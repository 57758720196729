<template>
    <b-alert variant="danger" dismissible :show="isVisible">
        <p class="mb-2">
            Não corra o risco de deixar suas hospedagens suspensas. Ative a recarga automática e mantenha seus serviços
            sempre ativos.
        </p>
        <div>
            <button type="button" class="btn btn-link p-0 font-weight-semibold" @click="showAutoRecharge">Clique e saiba mais</button>
            <button type="button" class="btn btn-link p-0 ml-5 text-muted" @click="hideAutoRechargeAlert">Não exibir novamente</button>
        </div>
    </b-alert>
</template>

<script>
    export default {
        data() {
            return {
                isVisible: true,
            }
        },
        methods:
        {
            showAutoRecharge()
            {
                this.isVisible = false;
                this.$router.push('/payment/auto-recharge');
            },

            hideAutoRechargeAlert()
            {
                this.isVisible = false;
                sessionStorage.setItem('hide_auto_recharge_alert', '1');

                const postData = {
                    preferences: {
                        hide_auto_recharge_alert: true,
                    }
                };

                this.$api.post('/user/preferences', postData);
            }
        }
    }
</script>
