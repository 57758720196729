<template>
    <button
        class="btn"
        :type="type"
        v-bind:class="[styleClass, isLoading ? 'btn-loading' : '']"
        v-bind:disabled="isDisabled || isLoading"
        @click="$emit('click')">
        <slot ref="text"/>
    </button>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: "button",
            },
            theme: {
                type: String,
                default: "",
            },
            variant: {
                type: String,
                default: "",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isDisabled: this.disabled,
                isLoading: this.loading,
            }
        },
        computed: {
            buttonVariant() {
                return this.theme || this.variant || 'primary';
            },
            styleClass() {
                return (this.buttonVariant ? 'btn-' + this.buttonVariant : '');
            },
        },
        methods:
        {
            setDisabled(isDisabled)
            {
                this.isDisabled = (typeof isDisabled == "undefined" || isDisabled !== false);
            },
            setLoading(isLoading, focus)
            {
                if (typeof focus !== "undefined" && focus) this.$el.focus();
                this.isLoading = this.isDisabled = (typeof isLoading == "undefined" || isLoading !== false);
            },
            loadingFocus()
            {
                this.setLoading(true, true);
            },
            setLoadingAndFocus()
            {
                this.loadingFocus();
            },
            removeLoading()
            {
                this.setLoading(false);
            },
            setText(text)
            {
                this.$el.innerHTML = text;
            }
        },
        watch: {
            disabled: function (val) {
                this.isDisabled = val;
            },
            loading: function (val) {
                this.isLoading = val;
            }
        }
    }
</script>